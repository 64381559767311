import { Auth } from '@aws-amplify/auth';

import { configureAmplify, USER_SRP_AUTH } from '@pumpkincare/portal-config';
import { ERROR, LOADING } from '@pumpkincare/portal-shared';

import { apiGet, setAppAuth } from '../state';

export default function fetchUser() {
  return dispatch => {
    configureAmplify(USER_SRP_AUTH);
    dispatch(setAppAuth({ status: LOADING, error: null }));

    return Auth.currentAuthenticatedUser()
      .catch(error => {
        dispatch(setAppAuth({ error, isLoggedIn: false, status: ERROR }, ERROR));
        throw error;
      })
      .then(user => {
        dispatch(setAppAuth({ isLoggedIn: true }));

        const headers = {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        };

        return dispatch(
          apiGet({
            url: '/users/self',
            options: {
              headers,
              onError: setAppAuth,
            },
          })
        ).then(data =>
          dispatch(
            apiGet({
              url: `/users/${data.id}`,
              options: { headers, onError: setAppAuth, onSuccess: setAppAuth },
            })
          )
        );
      });
  };
}
