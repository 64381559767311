import { analyticsTrack } from '@pumpkincare/portal-analytics';
import { useFormattedPlanLegal, usePlans } from '@pumpkincare/portal-app';
import {
  filterSpeciesPlan,
  formatSearchFromQuery,
  hasPrevent4Plans,
  useToggleState,
} from '@pumpkincare/portal-shared';
import {
  A,
  Body1,
  ButtonPrimary,
  ButtonSecondary,
  Processing,
  SubText1,
} from '@pumpkincare/portal-ui';
import { getVetSelfVetId, useVetSelf } from '@pumpkincare/portal-user';
import { getVetDisplayName, useVet } from '@pumpkincare/portal-vets';

import { PEP, PREVENTIVE_ESSENTIALS_CARD } from '../../constants';
import { PATH_EXT_SAMPLE_PLAN } from '../../routing/paths';
import LegalSection from '../legal-section';
import PEPCard from '../pep-card';
import SendMultiEmailModal from '../send-multi-email-modal';

import styles from './preventive-essentials.css';

import CatIcon from '../../../../../../../public/assets/paw-portal/illustrations/misc/cat-female.png';
import DogIcon from '../../../../../../../public/assets/paw-portal/illustrations/misc/dog-male.png';
import PreventiveEssentialsImage from '../../../../../../../public/assets/paw-portal/illustrations/multipet/preventive-essentials.png';

export default function PreventiveEssentials() {
  const [isSendMultiEmailModalOpen, toggleIsSendMultiEmailModalOpen] =
    useToggleState(false);

  const { data: vetId } = useVetSelf({ select: getVetSelfVetId });
  const { data: vetData } = useVet(vetId);
  const { data: plans, isPlaceholderData: isLoading } = usePlans(vetId);
  const { data: legal } = useFormattedPlanLegal(vetId);

  const vetName = getVetDisplayName(vetData);
  const dogPlans = filterSpeciesPlan(plans, 'dog');
  const catPlans = filterSpeciesPlan(plans, 'cat');

  const hasPEP4Plans = hasPrevent4Plans(plans);
  const pepVersion = plans[0]?.version_number;

  const formattedContent = {
    ...PREVENTIVE_ESSENTIALS_CARD,
    url: `${PREVENTIVE_ESSENTIALS_CARD.url}?${formatSearchFromQuery({
      vetId,
      pepVersion,
      vetName,
    })}`,
    vetName,
    emailBody: [
      PREVENTIVE_ESSENTIALS_CARD.emailBody[0].replace('OUR CLINIC', vetName),
      PREVENTIVE_ESSENTIALS_CARD.emailBodyByPlans(plans),
    ],
  };

  function getTargetCards(targetPlans) {
    return targetPlans.map(plan => {
      return <PEPCard key={plan.id} plan={plan} />;
    });
  }

  function handleEmailToClients() {
    analyticsTrack({
      event: 'Click Email to Clients from PEP Page | PEP',
      category: PEP,
    });
    toggleIsSendMultiEmailModalOpen();
  }

  return (
    <>
      <div className={styles.header}>
        <h3>Pumpkin Preventive Essentials</h3>
        <Body1 className={styles.subHeader}>
          Preventive Essentials is a wellness package that gives clients refunds for
          key routine care. It's not insurance, but an optional benefit they can add
          to their Pumpkin plan.
        </Body1>
        {!isLoading && !hasPEP4Plans ? (
          <div className={styles.pep3Wrapper}>
            <div className={styles.pep3Text}>
              <Body1 className={styles.pep3Header}>
                Design custom wellness packages for your clients!
              </Body1>
              <SubText1 className={styles.pep3SubHeader}>
                Work with us to create custom wellness packages so your clients can
                get refunds for the yearly wellness care you recommend for their
                pets! Chat with our team to learn more.
              </SubText1>
              <A
                onClick={() =>
                  analyticsTrack({
                    event: 'Click Get in Touch from PEP Page | PEP',
                    category: PEP,
                  })
                }
                href={`mailto:vetpartners@pumpkin.care?subject=Custom%20Preventive%20Essentials%20Inquiry&body=Hospital%20Name%3A%0D${encodeURIComponent(
                  vetName
                )}%0A%0D%0AI%20would%20like%20to%20learn%20more%20about%20Pumpkin’s%20custom%20wellness%20packages%20for%20my%20hospital.`}
              >
                <ButtonSecondary className={styles.pep3Button}>
                  Request More Info
                </ButtonSecondary>
              </A>
            </div>
            <img
              className={styles.pep3Image}
              src={PreventiveEssentialsImage}
              alt=''
            />
          </div>
        ) : null}
      </div>
      {!isLoading ? (
        <>
          <div className={styles.sendMail}>
            <Body1>
              {hasPEP4Plans
                ? 'Your hospital worked with Pumpkin to design these custom wellness packages! '
                : null}{' '}
              Here's a breakdown of what's available to your clients.
            </Body1>
            <ButtonPrimary onClick={handleEmailToClients}>
              Email to Clients
            </ButtonPrimary>
          </div>
          <div className={styles.cardsSection}>
            <div className={styles.cardsContainer}>
              {dogPlans.length ? (
                <div>
                  <div className={styles.targetTitle}>
                    <img src={DogIcon} alt='' />
                    <h3>For Dogs</h3>
                  </div>
                  {getTargetCards(dogPlans)}
                </div>
              ) : null}

              {catPlans.length ? (
                <div>
                  <div className={styles.targetTitle}>
                    <img src={CatIcon} alt='' />
                    <h3>For Cats</h3>
                  </div>
                  {getTargetCards(catPlans)}
                </div>
              ) : null}

              <Body1 className={styles.seeMore}>
                See more details in{' '}
                <A
                  href={`${PATH_EXT_SAMPLE_PLAN}?utm_source=vetmktPet&utm_medium=lp&utm_campaign=pawportal&utm_content=pep${pepVersion}&v=${pepVersion}&type=preventive`}
                  target='_blank'
                >
                  our sample Preventive Essentials Customer Agreement
                </A>
              </Body1>
            </div>
          </div>

          {legal.length ? <LegalSection legal={legal} /> : null}
        </>
      ) : (
        <Processing classes={{ container: styles.processing }} />
      )}

      {isSendMultiEmailModalOpen ? (
        <SendMultiEmailModal
          category={PEP}
          referrer='PEP Page'
          content={formattedContent}
          onClose={toggleIsSendMultiEmailModalOpen}
        />
      ) : null}
    </>
  );
}
