import { Auth } from '@aws-amplify/auth';

import { ERROR, LOADING, SUCCESS } from '@pumpkincare/portal-shared';

import { setAppResetPassword } from '../state';

export default function changePassword(password, currentPassword) {
  return dispatch => {
    dispatch(setAppResetPassword({ status: LOADING, error: null }));

    return Auth.currentAuthenticatedUser()
      .then(user => Auth.changePassword(user, currentPassword, password))
      .then(() => dispatch(setAppResetPassword({ status: SUCCESS }, SUCCESS)))
      .catch(error => {
        dispatch(setAppResetPassword({ status: ERROR, error }, ERROR));
        throw error;
      });
  };
}
