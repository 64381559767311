import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useNavContext, useVetProducts } from '@pumpkincare/portal-app';
import { useToggleState } from '@pumpkincare/portal-shared';
import {
  A,
  Body1,
  BodySubhead,
  Button,
  ButtonPrimary,
  ButtonSecondary,
  Popover,
} from '@pumpkincare/portal-ui';
import { useVetLogOut, useVetSelf, useVetUser } from '@pumpkincare/portal-user';
import { getVetState, useVet } from '@pumpkincare/portal-vets';

import { CONTACT_EMAIL_LINK } from '../../constants';
import { resetStoreState } from '../../redux/reducer';
import {
  PATH_ACCOUNT_BASE,
  PATH_ACCOUNT_PROFILE,
  PATH_EXT_BLOG,
  PATH_EXT_TOOLKIT,
  PATH_EXT_TOOLKIT_NONPEP,
  PATH_LOGIN,
  PATH_PATIENTS_BASE,
  PATH_PHOTO_CARD_GALLERY,
} from '../../routing/paths';

import styles from './nav-bar.css';

import close from '../../../../../../../public/assets/paw-portal/icons/close.svg';
import menu from '../../../../../../../public/assets/paw-portal/icons/menu.svg';
import cameraIcon from '../../../../../../../public/assets/paw-portal/illustrations/misc/camera.png';
import chartInactive from '../../../../../../../public/assets/paw-portal/illustrations/misc/chart-inactive.png';
import bag from '../../../../../../../public/assets/paw-portal/illustrations/misc/medical-bag.png';
import paw from '../../../../../../../public/assets/paw-portal/illustrations/misc/paw.png';
import search from '../../../../../../../public/assets/paw-portal/illustrations/misc/search.png';
import pumpkinLogo from '../../../../../../../public/assets/paw-portal/logos/pumpkin.svg';

function NavBar({ isBasicPawPortalNav, basicHeader }) {
  const dispatch = useDispatch();
  const logOut = useVetLogOut();

  const [isMenuOpen, toggleMenu] = useToggleState(false);
  const {
    data: { id: userId, vet_practice_id: vetId },
  } = useVetSelf({ enabled: !(basicHeader || isBasicPawPortalNav) });
  const { data: user } = useVetUser(userId);
  const { toggleNavBar } = useNavContext();

  const { data: vetData } = useVet(vetId);
  const { data: productsData } = useVetProducts(getVetState(vetData));
  const isPEPEnabled = productsData.prevent;

  if (basicHeader) {
    return (
      <div className={styles.basic}>
        <h3 className={styles.logo}>
          {basicHeader === 'pumpkin' ? <img src={pumpkinLogo} /> : basicHeader}
        </h3>
      </div>
    );
  }

  if (isBasicPawPortalNav) {
    return (
      <div className={styles.basicPawPortal}>
        <h3 className={styles.logo}>
          PawPortal<span className={styles.trademark}>™</span>
        </h3>
        <Body1>by Pumpkin</Body1>
      </div>
    );
  }

  function handleMenuClick() {
    toggleMenu();
    toggleNavBar();
  }

  function handleCloseMenu() {
    if (isMenuOpen) {
      toggleMenu();
    }
  }

  function handleSignOut() {
    logOut().then(() => {
      dispatch(resetStoreState());
    });
  }

  const toggleImage = isMenuOpen ? close : menu;
  const toggleLabel = isMenuOpen ? 'Close menu' : 'Open menu';

  return (
    <div className={styles.nav}>
      <Button
        className={styles.menuButton}
        aria-label={toggleLabel}
        onClick={handleMenuClick}
      >
        <img src={toggleImage} alt='Toggle menu' className={styles.toggleImage} />
      </Button>

      <Link
        to={PATH_ACCOUNT_BASE}
        onClick={handleCloseMenu}
        aria-label='Link to Home'
        className={styles.home}
      >
        <h3 className={styles.logo}>
          PawPortal<span className={styles.trademark}>™</span>
        </h3>
      </Link>

      <Link
        to={PATH_PATIENTS_BASE}
        onClick={handleCloseMenu}
        style={{ marginLeft: 'auto' }}
      >
        <ButtonPrimary className={styles.fileClaim}>View Patients</ButtonPrimary>
      </Link>

      {isMenuOpen ? (
        <Popover
          className={styles.popover}
          style={{ height: window.innerHeight - 72 }}
        >
          <div className={styles.account} onClick={toggleMenu}>
            <div className={styles.accountLogo}>
              {user?.first_name[0]}
              {user?.last_name[0]}
            </div>
            <div className={styles.accountName}>
              <b>
                {user?.first_name} {user?.last_name}
              </b>
              <Link to={PATH_ACCOUNT_PROFILE}>My Account</Link>
            </div>
            <Link to={PATH_LOGIN} onClick={handleSignOut} className={styles.signOut}>
              <b>Sign Out</b>
            </Link>
          </div>
          <A
            href={isPEPEnabled ? PATH_EXT_TOOLKIT : PATH_EXT_TOOLKIT_NONPEP}
            target='_blank'
            className={styles.menuItem}
          >
            <img
              src={bag}
              alt='bag'
              className={styles.linkIcon}
              role='presentation'
            />
            <BodySubhead>Hospital Toolkit</BodySubhead>
          </A>
          <A href={PATH_EXT_BLOG} target='_blank' className={styles.menuItem}>
            <img
              src={search}
              alt='search'
              className={styles.linkIcon}
              role='presentation'
            />
            <BodySubhead>Prrs&Wags Blog</BodySubhead>
          </A>
          <Link
            to={PATH_PATIENTS_BASE}
            onClick={handleMenuClick}
            className={styles.menuItem}
          >
            <img
              src={paw}
              alt='paw'
              className={styles.linkIcon}
              role='presentation'
            />
            <BodySubhead>Pumpkin Patients</BodySubhead>
          </Link>

          <Link
            to={PATH_PHOTO_CARD_GALLERY}
            className={styles.menuItem}
            onClick={handleMenuClick}
          >
            <img
              src={cameraIcon}
              alt='camera'
              className={styles.linkIcon}
              role='presentation'
            />
            <BodySubhead>Photo Cards</BodySubhead>
          </Link>

          <div className={classNames(styles.menuItem, styles.disabledText)}>
            <img
              src={chartInactive}
              alt='chart'
              className={styles.linkIcon}
              role='presentation'
            />
            <BodySubhead>Practice Insights (Coming Soon)</BodySubhead>
          </div>
          <A href={CONTACT_EMAIL_LINK}>
            <ButtonSecondary className={styles.contact}>
              Contact Pumpkin Care Team
            </ButtonSecondary>
          </A>
        </Popover>
      ) : null}
    </div>
  );
}

export default NavBar;
