import Auth from '@aws-amplify/auth';

import { configureAmplify, CUSTOM_AUTH } from '@pumpkincare/portal-config';

import { apiPost, setAppResetPassword } from '../state';
import checkUserToken from './check-user-token';
import fetchUser from './fetch-user';

export default function changePasswordLoggedOut(token, email, password) {
  return dispatch => {
    configureAmplify(CUSTOM_AUTH);

    return dispatch(checkUserToken(token, 'password_signup'))
      .then(() => Auth.signIn(email))
      .then(user => Auth.sendCustomChallengeAnswer(user, `token_vet::${token}`))
      .then(user =>
        dispatch(
          apiPost({
            url: '/users/self/change-password',
            options: {
              body: { password },
              headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
              },
              onSuccess: setAppResetPassword,
              onError: setAppResetPassword,
            },
          })
        )
      )
      .then(() => dispatch(fetchUser()));
  };
}
